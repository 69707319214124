// import { onSnapshot, applySnapshot } from "mobx-state-tree";
import { LocalStorageService } from "./localstorage-service";
import { taketravelApi } from "../../core/api";
import Service from "./service";
import { tagManager } from "../utils";
import { SETTINGS } from "../Config";

const TOKEN = {
  access_token: process.env.GUEST_TOKEN,
  token_type: process.env.GUEST_TOKEN_TYPE,
};

const getDefaultCy = (lang) => {
  switch (lang) {
    case "ru":
      return "rub";
    default:
      return process.env.CURRENCY;
  }
};

const guestData = (lang) => ({
  lang,
  cy: getDefaultCy(lang),
  token: TOKEN
});

class UserService extends Service {

  constructor(name, langs, store) {
    super(name);
    const { navigator, path } = langs;
    this.detectedLangs = [...new Set(navigator.concat(path))];
    this.store = store;
  }

  run() {
    let detectLang = this.detectedLangs.find(lang => SETTINGS.lang.includes(lang)) || process.env.LOCALIZATION;

    const promise = new Promise((resolve) => {
      import('mobx-state-tree')
        .then(({ onSnapshot, applySnapshot }) => {
          const iDispose = onSnapshot(this.store.userStore, (snapshot) => {
            const { cy, lang, token } = snapshot;
            LocalStorageService.setUserState(snapshot);
            taketravelApi.change({ lang, currency: cy, token: { type: token.token_type, token: token.access_token } });
            tagManager.setCurrency(cy);
            iDispose();
            resolve({ [this.name]: { cy, lang } });
          });
          
          try {
            if (LocalStorageService.hasBasketState()) {
              try {
                const basketObject = LocalStorageService.getBasketState();
                applySnapshot(this.store.basketStore, { ...basketObject, orders: basketObject.orders.map(order => ({ ...order, price: -1 })) });
              } catch (e) {
                console.warn("Can't apply basket store", e);
              }
            }

            if (LocalStorageService.hasUserState()) {
              const locUserState = LocalStorageService.getUserState();
              const token = locUserState.token;
              const now = Date.now();
              const nowUTC = parseInt(now / 1000);
              const expDate = parseInt(token && token.expires_in);

              if (isNaN(expDate) || nowUTC >= expDate) {
                locUserState.token = TOKEN;
              }
              if (!SETTINGS.lang.includes(locUserState.lang)) {
                locUserState.lang = detectLang;
              }
              if (!SETTINGS.cy.includes(locUserState.cy)) {
                locUserState.cy = getDefaultCy(locUserState.lang);
              }
              locUserState.watched = Array.isArray(locUserState.watched) ? [...new Set(locUserState.watched)] : [];
              applySnapshot(this.store.userStore, locUserState);
            } else {
              throw new Error('UserState is empty');
            }
          } catch (error) {
            applySnapshot(this.store.userStore, guestData(detectLang));
          }
        });
    });

    return promise;
  }
}

export default UserService;