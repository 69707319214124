import { parseLocation } from "../utils";
import Service from "./service";

// function navigatorDetector() {
//   var found = [];

//   if (!userBot()) {
//     if (navigator.languages) {
//       // chrome only; not an array, so can't use .push.apply instead of iterating
//       for (var i = 0; i < navigator.languages.length; i++) {
//         found.push(navigator.languages[i]);
//       }
//     }

//     if (navigator.userLanguage) {
//       found.push(navigator.userLanguage);
//     }

//     if (navigator.language) {
//       found.push(navigator.language);
//     }
//   }

//   return [...new Set(found.map(l => l.split("-")[0].toLowerCase()))];
// }

function pathDetect() {
  return parseLocation().lang || process.env.LOCALIZATION;
}

class LangDetectService extends Service {

  constructor(name) {
    super(name);
  }

  run() {
    return new Promise((resolve) => {
      const langs = {
        navigator: [],
        path: pathDetect()
      };
      resolve({ [this.name]: langs });
    });
  }
}

export default LangDetectService;