import axios from "axios";
// import i18n from "i18next";

import { num2word } from "../../core/plugins";

import { resources } from "../model/localization";
import LocalStorageService from "./localstorage-service";
import Service from "./service";

class I18NService extends Service {

  constructor(name, lang) {
    super(name);
    this.lang = lang;
  }

  run() {
    let storeI18n = LocalStorageService.getI18nState();
    let res = {};

    let needLoad = {};

    for (let lng in resources) {
      let bundle = resources[lng];
      let local = storeI18n[lng];
      if (local && local.url == bundle.url && local.translation) {
        res[lng] = { translation: local.translation };
      } else {
        res[lng] = { translation: bundle.translation };
        needLoad[lng] = bundle.url;
      }
    }
    return new Promise((resolve) => {
      let promise = Promise.resolve();
      let remoteJSON = needLoad[this.lang];
      if (Object.keys(res[this.lang].translation).length == 0 && remoteJSON) {
        promise = axios.get(remoteJSON)
          .then(({ data }) => {
            res[this.lang].translation = data;
            storeI18n[this.lang] = {
              url: remoteJSON,
              translation: data
            };
            LocalStorageService.setI18nState(storeI18n);
          });
      }
      promise
        .then(() => import('i18next'))
        .then((i18nModule) => {
          const i18n = i18nModule.default;
          i18n.use(num2word)
            .init({
              debug: process.env.DEBUG_MODE === "true",
              resources: res,
              lng: this.lang,
              fallbackLng: process.env.LOCALIZATION,
              postProcess: [num2word.name],
              interpolation: {
                escapeValue: false
              }
            }).then(() => {
              window._i18n = i18n;
              resolve({ [this.name]: i18n });
            });
        });
    });

  }
}

export default I18NService;