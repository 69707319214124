import { createContext, useState, useEffect, lazy, Suspense } from "react";
import { runServices, runPreServices, runPostServices } from '../services';
import { CircleIndicator } from '../components/common/indicators';

const App = lazy(() => import('./app'));

export const storeContext = createContext({});

function AppContext() {

  const [appState, setAppState] = useState({ state: 0, store: {} });

  useEffect(() => {
    runPreServices()
      .then((result) => {
        console.log("App is prepared");
        setAppState((data) => ({ state: 1, store: result.store || data.store }));
        return runServices(result);
      })
      .then((result) => {
        console.log("App is ready");
        setAppState((data) => ({ state: 2, store: result.store || data.store }));
        runPostServices(result);
      })
      .catch((e) => {
        console.error("Can't initialize project", e);
        setAppState(false);
      });
  }, []);

  // return ;
  return <storeContext.Provider value={appState.store}>
    <CircleIndicator active={appState.state != 2} margin="mt-5" />
    {appState.state != 0 && <Suspense fallback={<CircleIndicator active={appState.state != 2} margin="mt-5" />}>
      <App appState={appState.state} />
    </Suspense>}
  </storeContext.Provider>
}

export default AppContext;
