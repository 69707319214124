import isbot from "isbot";
import { taketravelApi } from "../../core/api";
import qs from "query-string";

const REG_DEF_LANG = new RegExp(`^\\/*\\b${process.env.LOCALIZATION}\\b`);

const _equalsLink = (a) => {
  const window_location = window && window.location;
  if (!window_location) return false;
  const path = window_location.href.replace(window_location.origin, "");
  return path == a || path == `/${a}`;
};

const _normalize = (link) => `/${link.replace(REG_DEF_LANG, "/")}`.replace(/\/{2,}/g, "/")
  .replace(/\?{2,}/g, "?")
  .replace(/#{2,}/g, "#")
  .replace(/={2,}/g, "=")
  .replace(/&{2,}/g, "&");

export const cannonical = (location) => {
  let url = ["/", (location || (window && window.location) || {}).pathname].filter(a => !!a).join("");
  let pathname = _normalize(url).replace(/\/$/, "");
  // eslint-disable-next-line no-useless-escape
  return process.env.HOST + pathname + (/^\/[a-z]{2}$/.test(pathname) ? "/" : "");
};

export const parseLocation = (location) => {
  if (!location) {
    location = window && window.location;
  } else if (typeof location['location'] === 'object') {
    location = location['location'];
  }
  // eslint-disable-next-line no-useless-escape
  const parseLang = location && location.pathname.match(/^\/([a-z]{2})(?:[\/\?\#]|$)/);
  // eslint-disable-next-line no-useless-escape
  const parsePath = location && location.pathname.match(/^(?:\/[a-z]{2})?((?:\/[^?\/]{3,})|\/)$/);
  const search = location && location.search;
  const hash = location && location.hash;
  const result = {
    lang: (parseLang && parseLang[1]) || "",
    pathname: (parsePath && parsePath[1]) || "",
    search: search || "",
    hash: hash || ""
  };
  return result;
};

export const buildHref = (localLocation) => {
  const { pathname, search, hash } = parseLocation();
  const lang = taketravelApi.lang;
  const newLink = [];
  if (typeof localLocation === "string") {
    newLink.push(lang, `/${localLocation}`);
  } else if (typeof location === "object") {
    newLink.push(localLocation.lang || lang,
      `/${localLocation.pathname || pathname}`,

      "search" in localLocation ? `?${localLocation.search}` : search,
      "hash" in localLocation ? `#${localLocation.hash}` : hash,

      // !localLocation.clearSearch && (localLocation.search || search) ? `?${localLocation.search || search}` : "",
      // !localLocation.clearHash && (localLocation.hash || hash) ? `#${localLocation.hash || hash}` : ""
    );
  } else {
    newLink.push(lang, "/");
  }
  return _normalize(newLink.join(""));
};

export const replaceLocation = (localLocation, force) => {
  const window_history = window && window.history;
  const window_location = window && window.location;
  const newHref = buildHref(localLocation);
  if (_equalsLink(newHref)) return true;
  if (force || !window_history || typeof window_history.replaceState !== "function") {
    if (window_location) {
      window_location.href = newHref;
    }
  } else if (window_history && typeof window_history.replaceState === "function") {
    window_history.replaceState(null, "", newHref);
  }
  return false;
};

export const userBot = () => isbot(navigator.userAgent);

export const qsStringify = (object) => qs.stringify(object, { arrayFormat: "comma" });
export const qsParse = (search) => qs.parse(search || "", { arrayFormat: "comma" });