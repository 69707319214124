import { taketravelApi } from "../../core/api";
import { URL, CHECK } from "../../core/api/objects";
import { SUCCESS } from "../../core/api/statuses";
import Service from "./service";
import { STATIC_LINKS } from "../screens";
import { WN, WindowEmitter } from "../windows";
import { dataAdapter } from "../../core/helpers";
import { replaceLocation, parseLocation } from "../utils";

function getOne(data) {
  return Array.isArray(data) && data.length ? data[0] : data;
}

class URLCheckService extends Service {
  constructor(name, langs, store) {
    super(name);
    this.langs = [...(new Set(langs))];
    this.store = store;
  }

  checkUrl(url, lang) {
    return new Promise((resolve) => {
      taketravelApi.get([URL, CHECK], { url, lang })
        .then(response => {
          if (response.data && response.data.status === SUCCESS) {
            const data = dataAdapter(response.data.data);
            if ("country" in data) {
              const one = getOne(data["country"]);
              if (one && one.url) {
                this.store.countryStore.addCountries(one);
                this.store.alternateStore.addAlternate(one.url, data["alternates"]);
              }
              resolve({ item: one, type: "country", lang: data.lang });
            } else if ("city" in data) {
              const one = getOne(data["city"]);
              if (one && one.url) {
                this.store.cityStore.addCities(one);
                this.store.alternateStore.addAlternate(one.url, data["alternates"]);
              }
              resolve({ item: one, type: "city", lang: data.lang });
            } else if ("division" in data) {
              const one = getOne(data["division"]);
              if (one && one.url) {
                this.store.divisionStore.addDivisions(one);
                this.store.alternateStore.addAlternate(one.url, data["alternates"]);
              }
              resolve({ item: one, type: "division", lang: data.lang });
            } else if ("category" in data) {
              const one = getOne(data["category"]);
              if (one && one.url) {
                this.store.categoryStore.addCategories(one);
                this.store.alternateStore.addAlternate(one.url, data["alternates"]);
              }
              resolve({ item: one, type: "category", lang: data.lang });
            } else if ("service" in data) {
              const one = getOne(data["service"]);
              if (one && one.url) {
                this.store.serviceStore.addServices(one);
                this.store.alternateStore.addAlternate(one.url, data["alternates"]);
              }
              resolve({ item: one, type: "service", lang: data.lang });
            }
          }
          throw new Error("Can't find");
        })
        .catch(() => {
          resolve({});
        });
    });
  }

  run() {
    return new Promise((resolve) => {
      const { pathname: url } = parseLocation();
      if (url && !STATIC_LINKS.includes(url)) {
        this.checkUrl(url, this.langs[0])
          .then((data) => resolve(data));
      } else {
        resolve({ item: { url: url || "/" }, lang: this.langs[0] });
      }
    })
      .then(({ item, lang, type }) => new Promise((resolve) => {
        if (item && item.url && this.langs[0] != lang) {
          WindowEmitter.open(WN.CHOISE_CONTENT_LANG, { lang, type, id: item.id });
        } else if (!item || !item.url || replaceLocation({ lang, pathname: item.url }, true)) {
          resolve({ [this.name]: true });
        }
      }))
      .catch(() => {
        replaceLocation({ lang: this.lang, pathname: "/" }, true);
      });
  }
}

export default URLCheckService;