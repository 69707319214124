// @ts-nocheck
/* Auth error interceptor */
// export const authInterceptor = (authErrorCallback) => {
//   return function (error) {
//     /* store original request */
//     let originalRequest = error.config;
//     /* check auth error */
//     if (
//       error.response &&
//       error.response.status === 401 &&
//       !originalRequest._retry
//     ) {
//       originalRequest._retry = true;
//       /* apply guest token to auth default config */
//       API_CONFIG.applyAuthToken(
//         API_CONFIG.guestToken,
//         API_CONFIG.tokenTypes.GUEST,
//       );
//       /* set new token to the stored request */
//       originalRequest.headers.Authorization = API_CONFIG.token;
//       /* notify handler about an auth error */
//       authErrorCallback && authErrorCallback();
//       /* retry request with the guest token */
//       return API_CONFIG.axios(originalRequest);
//     }
//     /* the request errors will return in the response */
//     return Promise.resolve(error);
//   };
// };

/* log request data */
export const logRequestInterceptor = (config) => {
  console.info(
    `%c=> TaketravelApi: ${config.url.split('?')[0]}`,
    'color:orange; font-size:12px',
  );
  console.groupCollapsed('=> <request>');
  console.info('%c timestamp:', 'color:cyan', Date.now());
  console.info('%c method:', 'color:cyan', `${config.method}`.toUpperCase());
  console.info('%c url:', 'color:cyan', `${config.baseURL}/${config.url}`);
  console.info('%c headers:', 'color:cyan', config.headers || {});
  console.info('%c data:', 'color:cyan', config.data || {});
  console.groupEnd();
  return config;
};

/* log response data */
export const logResponseInterceptor = (response) => {
  console.info(
    `%c<= TaketravelApi: ${response.config.url.split('?')[0]}`,
    'color:green; font-size:12px',
  );
  console.groupCollapsed('<= <response>');
  console.info('%c timestamp:', 'color:cyan', Date.now());
  console.info('%c status:', 'color:cyan', `${response.status}`.toUpperCase());
  console.info('%c headers:', 'color:cyan', response.headers);
  console.info('%c data:', 'color:cyan', response.data || {});
  console.groupEnd();
  return response;
};

/* throw auth error */
export const throwAuthErrorInterceptor = (config) => {
  /* disable for login request */
  if (config.url.includes('login')) {
    return config;
  }
  /* add random number to auth token */
  return {
    ...config,
    headers: {
      ...config.headers,
      common: {
        ...config.headers.common,
        Authorization: `${config.headers.common.Authorization}${Math.random()}`,
      },
    },
  };
};
