import { logRequestInterceptor, logResponseInterceptor } from "../../core/api/interceptors";
import { taketravelApi } from "../../core/api";
import Service from "./service";

class StoreService extends Service {
  constructor(name) {
    super(name);
  }

  run() {
    return new Promise((resolver) => {
      /* add base API url */
      taketravelApi.create(process.env.API_URL, {
        client_id: process.env.CLIENT_ID,
        client_secret: process.env.CLIENT_SECRET,
      }, {
        type: process.env.GUEST_TOKEN_TYPE,
        token: process.env.GUEST_TOKEN
      });
      console.log('IMPORT_SERVICES');

      Promise.all([import('../../core/model/store'), import('mobx-react-lite')])
        .then(([rootStore, mobxReactLite]) => {
          const store = rootStore.default;
          console.log('mobxReactLite', mobxReactLite);
          window._observer = mobxReactLite.observer;
          if (process.env.DEBUG_MODE === "true") {
            import('mobx-state-tree')
              .then(({ onSnapshot }) => {
                Object.keys(store).forEach((storeName) => {
                  onSnapshot(store[storeName], (state) => {
                    console.groupCollapsed(`${String(store[storeName])}`);
                    Object.keys(state).forEach((key) => {
                      console.info(`%c${key}`, 'color:green', state[key]);
                    });
                    console.groupEnd();
                  });
                });
              });
            taketravelApi.addRequestInterceptor(logRequestInterceptor);
            taketravelApi.addResponseInterceptor(logResponseInterceptor);
          }
          resolver({ [this.name]: store });
        });
    })
  }
}

export default StoreService;