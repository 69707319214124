import LangDetectService from "./lang-detect-service";
import StoreService from "./store-service";
import UserService from "./user-service";
import I18NService from "./i18n-service";
import URLCheckService from "./urlcheck-service";
import PreLoadService from "./preload-service";
import CookieService from "./cookie-service";
import SaveStoreService from "./save-store-service";

const collectData = (list) => list.reduce((prev, obj) => {
  for (let name in obj) {
    prev[name] = obj[name];
  }
  return prev;
}, {});

export const runPreServices = () => {
  return Promise.all(
    [new StoreService('store'), new LangDetectService("langs")]
      .map(s => s.run())
  ).then((list) => {
    let data = collectData(list);
    return Promise.all(
      [new UserService("user", data.langs, data.store)]
        .map(s => s.run())
        .concat(Promise.resolve({ ...data })));
  }).then((list) => {
    let data = collectData(list);
    return Promise.all(
      [new I18NService("i18n", data.user.lang)]
        .map(s => s.run())
        .concat(Promise.resolve({ ...data })));
  }).then(list => Promise.resolve(collectData(list)));
};

export const runServices = (data) => {
  return Promise.all(
    [new SaveStoreService("save-changes", data.store),
    new URLCheckService("url", [data.langs.path, data.user.lang], data.store),
    new PreLoadService('preload', data.store)]
      .map(s => s.run())
      .concat(Promise.resolve({ ...data }))
  ).then(list => Promise.resolve(collectData(list)));
}

export const runPostServices = (data) => {
  return new Promise((resolve) => {
    setTimeout(resolve, 500);
  }).then(() => {
    return Promise.all(
      [new CookieService(data.store)].map(s => s.run())
    );
  });
};